#content {
  height: 90vh;
}

.screen-size {
  max-height: 100vh;
}

.no-overflow {
  overflow-y: hidden;
}

.heading,
.heading .title {
  color: #000 !important;
}

.heading .subtitle {
  display: none;
}

.heading:hover .title {
  display: none;
}

.heading:hover .subtitle {
  display: block;
}

.title {
  position: absolute;
  top: 0;
  left: 100px;
  z-index: 999;
}

.title {
  font-size: 1.8em;
}

.subtitle {
  position: absolute;
  top: 0;
  left: 100px;
  z-index: 999;
  line-height: 3.25em;
}
