.outeractioncircle {
  background: #f86767;
  color: white;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  z-index: 2147483646;
  font-size: 18px;
  position: absolute;
  bottom: 80px;
  right: 30px;
  z-index: 9999;
  font-weight: bolder;
}

.menu-icon-close {
  font-size: 24px;
}

.outeractioncircle:hover {
  background-color: #d92b36;
}

.outeractioncircle-one {
  background: #f86767;
  color: #fff;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  z-index: 2147483646;
  font-size: 24px;
  position: absolute;
  bottom: -285px;
  right: 30px;
  z-index: 99999;
}

.outeractioncircle-one:hover {
  background-color: #d92b36;
}

.outeractioncircle-two {
  background: #f86767;
  color: #fff;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  z-index: 2147483646;
  font-size: 24px;
  position: absolute;
  bottom: -195px;
  right: 30px;
  z-index: 99999;
}

.outeractioncircle-three {
  background: #f86767;
  color: #fff;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  z-index: 2147483646;
  font-size: 24px;
  position: absolute;
  bottom: -105px;
  right: 30px;
  z-index: 99999;
}

.outeractioncircle-two:hover {
  background-color: #d92b36;
}

.outeractioncircle-three:hover {
  background-color: #d92b36;
}

.outeractionbutton-container {
  z-index: 2147483646;
}

@media screen and (max-width: 1441px) and (min-width: 1181px) {
  .outeractioncircle-one {
    bottom: -285px;
  }
  .outeractioncircle-two {
    bottom: -200px;
  }
  .outeractioncircle-three {
    bottom: -115px;
  }
}

@media screen and (max-width: 450px) {
  .outeractioncircle {
    top: 20px;
    left: 18px;
    width: 40px;
    height: 40px;
  }
  .outeractioncircle-one {
    top: 20px;
    right: -90px;
    width: 40px;
    height: 40px;
  }
  .outeractioncircle-two {
    top: -2px;
    right: -140px;
    width: 40px;
    height: 40px;
  }
  .outeractioncircle-three {
    top: -24px;
    right: -190px;
    width: 40px;
    height: 40px;
  }
}

@media screen and (max-width: 600px) {
  .outeractioncircle {
    top: 20px;
    left: 18px;
    width: 40px;
    height: 40px;
  }
  .outeractioncircle-one {
    top: 20px;
    right: -90px;
    width: 40px;
    height: 40px;
  }
  .outeractioncircle-two {
    top: -2px;
    right: -140px;
    width: 40px;
    height: 40px;
  }
  .outeractioncircle-three {
    top: -24px;
    right: -190px;
    width: 40px;
    height: 40px;
  }
}

@media only screen and (max-height: 800px) and (min-height: 700px) {
  .outeractioncircle-one {
    top: 160px;
  }
  .outeractioncircle-two {
    top: 70px;
  }
  .outeractioncircle-three {
    top: -20px;
  }
}
/* fix mobile fade thing */
