.messageForm-container {
  padding-top: 45px;
}

.contact-section {
  background-color: #2f2f32;
}

.message-sent {
  background: #028824;
  margin: 0;
  margin-top: -2em;
}

.bottom-half {
  background-color: black;
  height: 345px;
}

.bottom-down-below {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  background-color: black !important;
}

.socialBeam {
  list-style: none;
}

.socialBeam li {
  display: inline-block;
  padding: 1em;
}

.socialBeam a {
  color: #fff !important;
  font-size: 2em;
}

.footer-headings {
  color: #f86767 !important;
  font-size: 30px;
  font: arial !important;
  display: flex;
  justify-content: center;
  position: relative;
  top: 30px;
  left: -4px;
}

.footer-text {
  color: white;
  display: flex;
  justify-content: center;
  left: 10px;
  position: relative;
  top: 15px;
}

.anomaly-footer {
  left: -3px;
}

.anomaly-heading {
  display: block;
  position: relative;
  left: 225px;
  top: -89px;
}

.socialBeam {
  position: relative !important;
  left: 206px !important;
  top: -124px !important;
}

.socialMedia-container {
  position: relative;
  left: -16px !important;
  top: -20px;
}

.contact-footer {
  color: white !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  height: 55px;
  border-top: 1px solid white;
}

.contact-details {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.contact-detail {
  margin: 0;
}

.anomaly-heading {
  display: block;
  position: relative;
  left: 236px;
  top: -89px;
}

.contact-footer {
  top: 0px;
}

.contact-address {
  left: 118px;
  top: 5px;
}

.contact-details {
  left: 260px;
}

.contact-header {
  left: 37px;
}

.address-header {
  left: 108px;
}

.footer-bottom-text {
  margin: 0;
}

.french-address {
  left: 108px;
  top: 0px;
}

@media screen and (max-width: 450px) {
  .socialBeam {
    position: relative !important;
    left: 3px !important;
    top: -61px !important;
  }

  .anomaly-heading {
    display: block;
    position: relative;
    left: 86px !important;
    top: -15px !important;
  }

  .socialMedia-container {
    padding-top: 19px;
    top: -5px;
    left: 28px !important;
  }

  .messageForm-container {
    padding-top: 5px;
  }

  .contact-button {
    position: absolute;
    right: 11px;
    bottom: 25px;
  }

  .contact-footer {
    position: absolute;
    top: 1155px;
    height: 60px;
  }

  .bottom-half {
    height: 449px;
  }

  .footer-bottom-text {
    text-align: center;
    position: relative;
  }

  .contact-details {
    left: 19px;
    top: -15px;
  }

  .contact-header {
    left: -3px;
    top: 10px;
  }

  .contact-address {
    left: 23px;
    top: -16px;
  }

  .french-address {
    left: 13px;
    top: -22px;
  }

  .address-header {
    top: 10px;
    left: 8px;
  }

  .footer-bottom-text {
    margin: 0;
  }
}

@media screen and (max-width: 400px) {
  .socialBeam {
    position: relative !important;
    left: 3px !important;
    top: -61px !important;
  }

  .anomaly-heading {
    display: block;
    position: relative;
    left: 86px !important;
    top: -15px !important;
  }

  .socialMedia-container {
    padding-top: 19px;
    top: -5px;
    left: 28px !important;
  }

  .messageForm-container {
    padding-top: 5px;
  }

  .contact-button {
    position: absolute;
    right: 11px;
    bottom: 25px;
  }

  .contact-footer {
    position: absolute;
    top: 1155px;
    height: 60px;
  }

  .bottom-half {
    height: 449px;
  }

  .footer-bottom-text {
    text-align: center;
    position: relative;
  }

  .contact-details {
    left: 19px;
    top: -15px;
  }

  .contact-header {
    left: -3px;
    top: 10px;
  }

  .contact-address {
    left: 23px;
    top: -16px;
  }

  .french-address {
    left: 13px;
    top: -22px;
  }

  .address-header {
    top: 10px;
    left: 8px;
  }

  .footer-bottom-text {
    margin: 0;
  }

  .footer-text {
    left: 15px;
  }

  .anomaly-footer {
    left: 1.5px;
  }
}

/* iPad Landscape */
@media screen and (max-width: 1180px) and (min-width: 401px) {
  .socialHeader-list {
    position: relative !important;
    left: 129px !important;
    top: -121px !important;
  }

  .anomaly-heading {
    display: block;
    position: relative;
    left: 236px;
    top: -89px;
  }

  .contact-footer {
    top: 0px;
  }

  .contact-address {
    left: 101px;
    top: 5px;
  }

  .contact-details {
    left: 236px;
  }

  .contact-header {
    left: 63px;
  }

  .address-header {
    left: 91px;
  }

  .footer-bottom-text {
    margin: 0;
  }

  .french-address {
    left: 92px;
    top: 0px;
  }

  .socialMedia-container {
    left: -115px !important;
    top: -29px;
  }

  .footer-text {
    left: 17px;
  }

  .anomaly-footer {
    left: 3px;
  }
}

@media screen and (min-width: 1024px) {
  .socialMedia-container {
    left: -100px !important;
    top: -20px !important;
  }

  .footer-text {
    left: 10px;
  }

  .anomaly-footer {
    left: -1px;
  }
}

@media screen and (min-width: 1440px) {
  .socialMedia-container {
    left: -18px !important;
    top: -20px !important;
  }

  .footer-text {
    left: 10px;
  }

  .anomaly-footer {
    left: -1px;
  }
}

@media screen and (min-width: 1600px) {
  .socialMedia-container {
    left: 15px !important;
    top: -20px !important;
  }

  .footer-text {
    left: 8px;
  }

  .anomaly-footer {
    left: -5px;
  }
}

.fb:hover {
  color: #4a76cc;
}

.insta:hover {
  color: #af37ff;
}

.twt:hover {
  color: #00acee;
}
.linkedin:hover {
  color: #1593ce;
}
.whtsapp:hover {
  color: #25d366;
  cursor: pointer;
}
