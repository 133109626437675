body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

#banner {
  padding: 0;
  height: 89vh;
  min-height: 0vh !important;
  background-color: black;
}

#banner a {
  margin-right: 20px;
}

.start-red {
  background: #f86767 !important;
  color: #fff !important;
}

.start-red:hover {
  background-color: #d92b36 !important;
}

.entry-footer {
  position: absolute;
  bottom: 14px;
  color: white;
  width: 100%;
  background: hsla(0, 0%, 100%, 0.3);
  text-align: center;
  font-size: 12px;
}

.homepage-actionbutton {
  z-index: 2147483646;
  height: 10vh;
  width: 5vw;
}

.inner {
  top: -30px;
}

@media screen and (max-width: 450px) {
  body {
    overflow: auto !important;
  }

  .homepage-actionbutton {
    height: 2vh;
    width: 5vw;
    position: absolute;
    top: -6px;
    left: 2px;
    z-index: 9;
  }

  .button-stylings {
    width: 300px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 78px;
    height: 55px !important;
    font-size: 16px !important;
  }

  .white-button {
    margin-bottom: 20px !important;
    margin-top: 23px;
  }

  .red-button {
    background-color: #f86767 !important;
  }
}

@media screen and (max-width: 400px) {
  body {
    overflow: auto !important;
  }

  .homepage-actionbutton {
    height: 2vh;
    width: 5vw;
    position: absolute;
    top: -6px;
    left: 2px;
    z-index: 9;
  }

  #banner {
    height: 559px !important;
    padding: 0;
    height: 107vh;
    min-height: 0vh !important;
  }

  .button-stylings {
    width: 300px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 40px;
    height: 55px !important;
    font-size: 16px !important;
  }

  .white-button {
    margin-bottom: 20px !important;
    margin-top: 23px;
  }

  .entry-footer {
    bottom: 12px;
    font-size: 12px;
  }

  .red-button {
    background-color: #f86767 !important;
  }

  #banner {
    padding: 0;
    height: 107vh;
    min-height: 0vh !important;
  }
  .inner {
    top: -10px !important;
  }
}

@media screen and (max-width: 600px) {
  body {
    overflow: auto !important;
  }

  .homepage-actionbutton {
    height: 2vh;
    width: 5vw;
    position: absolute;
    top: -6px;
    left: 2px;
    z-index: 9;
  }

  .button-stylings {
    width: 300px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 78px;
    height: 55px !important;
    font-size: 16px !important;
  }

  .white-button {
    margin-bottom: 20px !important;
    margin-top: 23px;
  }

  .red-button {
    background-color: #f86767 !important;
  }
}

@media screen and (max-width: 1180px) and (min-width: 451px) {
  .button-stylings {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 300px !important;
    padding: 40px !important;
    margin-top: 10px !important;
    margin-right: 30px !important;
    margin-left: 30px !important;
  }

  .white-button {
    width: 300px !important;
  }

  .red-button {
    background-color: #f86767 !important;
    margin-bottom: 50px !important;
  }

  .entry-footer {
    bottom: 22px;
    font-size: 10px;
  }

  .inner {
    top: -10px;
  }
}
