.overflow-shown {
  overflow: auto;
}

.portfolio-page {
  /* padding-top:4em; */
  min-height: 100vh !important;
  background-image: url(../../images/rainbow-what-we-do.jpeg);
  object-fit: contain;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(47, 79, 79, 0.7);
  background-blend-mode: multiply;
}

.portfolio-page header {
  float: left;
}

.portfolio-page a:hover {
  cursor: pointer;
}

.outer-container {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.categories {
  display: flex;
  justify-content: center;
  list-style: none;
}

.header {
  text-align: center;
  padding-top: 45px;
  color: white !important;
  font-size: 44px;
}

.header span {
  color: #f86767 !important;
}

.portfolio-container {
  text-align: center;
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  grid-gap: 70px;
  background-color: transparent;
  padding-bottom: 45px;
}

@media screen and (max-width: 768px) {
  .portfolio-container {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 1100px) and (min-width: 451px) {
}

@media screen and (max-width: 450px) {
}

/* Scrollbars */

/* Firefox */
/* .portfolio-page .inner {
  scrollbar-width: thin;
  scrollbar-color: blue orange;
} */

/* Works on Chrome, Edge, and Safari */
/* .portfolio-page .inner::-webkit-scrollbar {
  width: 15px;
}

.portfolio-page .inner::-webkit-scrollbar-track {
  background: white;
  border-radius: 25px;
  margin:10px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.portfolio-page .inner::-webkit-scrollbar-thumb {
  background-color: #000;
  border-radius: 25px;
  border: 1px solid white;
  
} */

.white-to-greyscale {
  filter: brightness(1) grayscale(1) invert(1);
}

.colour-to-greyscale {
  filter: grayscale(1);
}

/*Filter styles*/
.contrast {
  filter: contrast(160%);
}
.saturate {
  filter: saturate(3);
}
.grayscale {
  filter: grayscale(100%);
}
.brightness {
  filter: brightness(0.25);
}
.blur {
  filter: blur(3px);
}
.invert {
  filter: invert(100%);
}
.sepia {
  filter: sepia(100%);
}
.huerotate {
  filter: hue-rotate(180deg);
}
.rss.opacity {
  filter: opacity(50%);
}

/* Mobile view */
@media screen and (max-width: 450px) {
  .outer-container {
    position: relative;
    left: -13px;
  }
}

@media screen and (max-width: 600px) {
  .outer-container {
    position: relative;
    left: -13px;
  }
}

/* iPad Landscape */
@media screen and (max-width: 1100px) and (min-width: 451px) {
}
