* {
  margin: 0;
  padding: 0;
}

.page-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.contactModalContainer {
  position: fixed;
  z-index: 90;
  width: 800px;
  height: 400px;
  top: 26%;
  left: 25em;
  /* transition: opacity 500ms; */
  transition: opacity 0.5s linear;
  /* box-shadow: 5px 5px 15px 5px #000000; */
  border-radius: 8px;
  /* background-color: #eee; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  /* box-shadow: 5px 5px 5px 0px #000000, inset 4px 4px 15px 0px #000000, 5px 5px 15px 5px rgba(0,0,0,0); */
}

.success {
  background-color: #4bb543;
}

.success-animation {
  animation: success-pulse 2s infinite;
}

.contactModal {
  position: relative;
  width: 350px;
  min-height: 250px;
  background-color: #fff;
  background-color: #2e3032;
  border-radius: 30px;
  margin: 40px 10px;
  box-shadow: 5px 5px 15px 5px black;
  box-shadow: 5px 5px 0px 0px #4b4c4b, 5px 5px 15px 5px rgba(28, 28, 28, 0);
  box-shadow: -10px 0px 13px -7px #4b4c4b, 10px 0px 13px -7px #4b4c4b,
    5px 5px 15px 5px rgba(0, 0, 0, 0);
  /* box-shadow: 5px 5px 5px 0px #000000, inset 4px 4px 15px 0px #000000, 5px 5px 15px 5px rgba(0,0,0,0); */
}

.contactModal-content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 0;
  flex-direction: column;
  margin-bottom: 5px;
  top: 65px;
}

.contactModal-title {
  font-size: 18px;
  color: #999;
  color: white;

  margin: 10px 0px !important;
  font-family: Sansita;
  font-style: normal;
}

.contactModal .contactModal-content .contactModal-message {
  font-size: 16px;
  color: #000;
  color: #daddda;
  padding: 0px 15px;
  margin: 5px !important;
  font-family: Sansita;
  font-style: normal;
}

.contactModal .border-bottom {
  position: absolute;
  width: 300px;
  height: 20px;
  border-radius: 0 0 30px 30px;
  bottom: -20px;
  margin: 0 25px;
}

.contactModal .icon-top {
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  top: -30px;
  margin: 0 125px;
  font-size: 45px;
  color: #fff;
  line-height: 100px;
  text-align: center;
}

.contactModal-closeBtn {
  text-align: center;
  margin-top: 6px;
}

@keyframes success-pulse {
  0% {
    box-shadow: 0px 0px 30px 20px rgba(75, 181, 67, 0.2);
  }

  50% {
    box-shadow: 0px 0px 30px 20px rgba(75, 181, 67, 0.4);
  }

  100% {
    box-shadow: 0px 0px 30px 20px rgba(75, 181, 67, 0.2);
  }
}

@media only screen and (max-width: 800px) {
  .contactModalContainer {
    flex-direction: column;
  }
}

@media screen and (max-width: 450px) {
  .contactModalContainer {
    position: fixed;
    z-index: 90;
    width: 800px;
    height: 400px;
    top: 25%;
    left: -13em;
    /* transition: opacity 500ms; */
    transition: opacity 0.5s linear;
    /* box-shadow: 5px 5px 15px 5px #000000; */
    border-radius: 8px;
    /* background-color: #eee; */
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
}

@media screen and (max-width: 600px) {
  .contactModalContainer {
    position: fixed;
    z-index: 90;
    width: 800px;
    height: 400px;
    top: 25%;
    left: -13em;
    /* transition: opacity 500ms; */
    transition: opacity 0.5s linear;
    /* box-shadow: 5px 5px 15px 5px #000000; */
    border-radius: 8px;
    /* background-color: #eee; */
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
}
