.card {
  height: 400px;
  width: 330px;
  border-radius: 8px;
  cursor: pointer;
  background-color: white;
  box-shadow: 5px 5px 15px 5px #000000;
}
.card:hover {
  box-shadow: 8px 8px 0px 0px #f86767, 36px 22px 10px 7px rgba(0, 0, 0, 0);
}

.btn-pink-more {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 285px;
  font-size: 16px;
  background-color: #f86767 !important;
  color: white !important;
  border-radius: 49%;
}

.btn-pink-more:hover {
  background-color: #f86767 !important;
}

.btn-container {
  width: 220px;
  height: 270px;
  padding-top: 8px;
  padding-left: 20px;
  display: none;
}

.desc-container {
  width: 320px;
  height: 235px;
  padding-top: 10px;
  padding-left: 30px;
  /* display: inline; */
  font-size: 16px;
  color: black;
}

.card:hover .desc-container {
  display: none;
}

.card:hover .btn-container {
  display: flex;
  grid-gap: 10px;
  flex-direction: column;
  gap: 10px;
}

.heading-container {
  width: 330px;
  height: 200px;
  grid-template-columns: repeat(2, 1fr);
}

.info {
  display: flex;
  justify-content: flex-end;
  font-size: 30px;
  padding-right: 10px;
  padding-top: 20px;
}

.hover-button .hover-button--on,
.hover-button:hover .hover-button--off {
  display: none;
}

.hover-button:hover .hover-button--on {
  display: inline;
}

.card-clickIcon {
  transform: rotate(25deg);
  color: #000000;
}

.main-title {
  /* padding-top: 10px; */
  padding-left: 30px;
  overflow: visible;
  font-size: 2em;
  width: 250px;
}

@media screen and (min-width: 1441px) {
  .main-title {
    width: 300px;
  }
}

@media screen and (max-width: 400px) {
  .card {
    height: 250px;
    width: 280px;
    border-radius: 8px;
    cursor: pointer;
    background-color: white;
    box-shadow: 5px 5px 15px 5px #000000;
  }
  .heading-container {
    height: 100px;
    width: 260px;
    grid-template-columns: repeat(2, 1fr);
  }
  .main-title {
    font-size: 1.5em;
    width: 244px;
  }
  .btn-container {
    padding-top: 43px;
    padding-left: 49px;
  }
  .btn-pink-more {
    height: 26px;
    width: 190px;
    font-size: 11px;
  }
  .desc-container {
    width: 235px;
    height: 235px;
    padding-top: 52px;
    padding-left: 30px;
    /* display: inline; */
    font-size: 16px;
    color: black;
  }
}

@media screen and (max-width: 450px) {
  .heading-container {
    width: 430px;
    height: 400px;
    grid-template-columns: repeat(2, 1fr);
  }
  .card {
    height: 250px;
    width: 280px;
    border-radius: 8px;
    cursor: pointer;
    background-color: white;
    box-shadow: 5px 5px 15px 5px #000000;
  }
  .heading-container {
    height: 100px;
    width: 260px;
    grid-template-columns: repeat(2, 1fr);
  }
  .main-title {
    font-size: 1.5em;
    width: 244px;
  }
  .btn-container {
    padding-top: 13px;
    padding-left: 19px;
  }
  .btn-pink-more {
    height: 26px;
    width: 190px;
    font-size: 11px;
  }
  .desc-container {
    width: 235px;
    height: 235px;
    padding-top: 52px;
    padding-left: 30px;
    /* display: inline; */
    font-size: 16px;
    color: black;
  }
}

@media screen and (max-width: 600px) {
  .heading-container {
    width: 430px;
    height: 400px;
    grid-template-columns: repeat(2, 1fr);
  }
  .card {
    height: 250px;
    width: 280px;
    border-radius: 8px;
    cursor: pointer;
    background-color: white;
    box-shadow: 5px 5px 15px 5px #000000;
  }
  .heading-container {
    height: 100px;
    width: 260px;
    grid-template-columns: repeat(2, 1fr);
  }
  .main-title {
    font-size: 1.5em;
    width: 244px;
  }
  .btn-container {
    padding-top: 13px;
    padding-left: 19px;
  }
  .btn-pink-more {
    height: 26px;
    width: 190px;
    font-size: 11px;
  }
  .desc-container {
    width: 235px;
    height: 235px;
    padding-top: 52px;
    padding-left: 30px;
    /* display: inline; */
    font-size: 16px;
    color: black;
  }
}

@media screen and (max-width: 1100px) and (min-width: 451px) {
  .card {
    height: 300px;
    width: 250px;
    border-radius: 8px;
    cursor: pointer;
    background-color: white;
    box-shadow: 5px 5px 15px 5px #000000;
  }
  .heading-container {
    height: 120px;
    width: 240px;
    grid-template-columns: repeat(2, 1fr);
  }
  .btn-container {
    padding-top: 100px;
    padding-left: 30px;
  }
  .btn-pink-more {
    height: 40px;
    width: 200px;
    font-size: 20px;
  }
}
