.top-section {
  padding-top: 50px;
  text-align: center;
  font-size: 20px;
}

.services-title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.desc {
  padding-left: 20%;
  padding-right: 20%;
}

@media screen and (max-width: 450px) {
  .top-section {
    padding-top: 10px;
  }

  .desc {
    font-size: 14px;
    padding-left: 12px;
    padding-right: 12px;
  }
}

@media screen and (max-width: 600px) {
  .top-section {
    padding-top: 10px;
  }

  .desc {
    font-size: 14px;
    padding-left: 12px;
    padding-right: 12px;
  }
}

@media only screen and (max-height: 800px) and (min-height: 700px) {
  .top-section {
    padding-top: 10px;
    text-align: center;
    font-size: 20px;
  }
  h2 {
    margin-bottom: 10px;
  }
}
