.emailModal {
  width: 800px;
  height: 400px;
  position: fixed;
  top: 25%;
  left: 25%;
  background: white;
  transition: opacity 500ms;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  box-shadow: 5px 5px 15px 5px #000000;
  border-radius: 8px;
  flex-direction: column;
}

.emailModal-header {
  margin-bottom: 100px;
}

.emailModal-btn-one {
  margin-right: 70px;
  height: 65px;
  width: 256px;
  font-size: 18px;
  background-color: #fff !important;
  color: black !important;
  box-shadow: 5px 5px 15px 5px darkgray;
  padding-left: 38px;
}

.emailModal-btn-two {
  height: 65px;
  width: 256px;
  font-size: 18px;
  background-color: #f86767 !important;
  color: white !important;
  box-shadow: 5px 5px 15px 5px darkgray;
  padding-left: 40px;
}

.emailModal-btn-two:hover {
  box-shadow: 5px 5px 15px 5px black;
}

.emailModal-btn-one:hover {
  box-shadow: 5px 5px 15px 5px black;
}

.emailModal-btns {
  flex-direction: row;
}

.emailModal-icon-one {
  position: absolute;
  top: 267px;
  left: 450px;
  color: white !important;
}

.emailModal-icon-two {
  position: absolute;
  top: 267px;
  left: 127px;
  color: black !important;
}

.emailModal-close {
  color: white;
  float: right;
  background-color: black;
  border: solid 1px #c3073f;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 2px;
  font-size: 25px;
  position: absolute;
  top: 4px;
  right: 4px;
}

.emailModal-split {
  color: black !important;
  margin-right: 45px !important;
  height: 50px;
  width: 1px;
}

.vr {
  border-left: 3px solid black;
  height: 75px;
  position: absolute;
  left: 50%;
  margin-left: -3px;
  top: 60%;
}

@media screen and (max-width: 1100px) and (min-width: 451px) {
  .emailModal {
    width: 650px;
    height: 350px;
    position: fixed;
    top: 22%;
    left: 16%;
  }
  .emailModal-btn-one {
    font-size: 15px;
    height: 55px;
    width: 226px;
  }
  .emailModal-btn-two {
    font-size: 15px;
    height: 55px;
    width: 226px;
  }
  .emailModal-icon-one {
    position: absolute;
    top: 244px;
    left: 374px;
  }
  .emailModal-icon-two {
    position: absolute;
    top: 244px;
    left: 78px;
  }
  .vr {
    border-left: 3px solid black;
    height: 67px;
    position: absolute;
    left: 50%;
    margin-left: 0px;
    top: 63%;
  }
}

@media screen and (max-width: 450px) {
  .emailModal {
    width: 346px;
    height: 475px;
    position: fixed;
    top: 16%;
    left: 3%;
  }
  .emailModal-btn-one {
    font-size: 15px;
    height: 55px;
    width: 226px;
    margin: unset;
    margin-bottom: 40px;
    padding-left: 18px;
  }
  .emailModal-btn-two {
    font-size: 15px;
    height: 55px;
    width: 226px;
    padding-left: 18px;
  }
  .emailModal-icon-one {
    position: absolute;
    top: 346px;
    left: 72px;
    z-index: 99;
  }
  .emailModal-icon-two {
    position: absolute;
    top: 252px;
    left: 72px;
  }
  .vr {
    display: none;
  }
  .emailModal-btns {
    flex-direction: column;
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 600px) {
  .emailModal {
    width: 346px;
    height: 475px;
    position: fixed;
    top: 16%;
    left: 3%;
  }
  .emailModal-btn-one {
    font-size: 15px;
    height: 55px;
    width: 226px;
    margin: unset;
    margin-bottom: 40px;
    padding-left: 18px;
  }
  .emailModal-btn-two {
    font-size: 15px;
    height: 55px;
    width: 226px;
    padding-left: 18px;
  }
  .emailModal-icon-one {
    position: absolute;
    top: 346px;
    left: 72px;
    z-index: 99;
  }
  .emailModal-icon-two {
    position: absolute;
    top: 252px;
    left: 72px;
  }
  .vr {
    display: none;
  }
  .emailModal-btns {
    flex-direction: column;
    display: flex;
    align-items: center;
  }
}
