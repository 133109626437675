.caseStudy {
  width: 630px;
  height: 626px;
  position: fixed;
  top: 15.5%;
  left: 415px;
  background: white;
  transition: opacity 500ms;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  box-shadow: 5px 5px 15px 5px #000000;
  border-radius: 8px;
  flex-direction: column;
}

.caseStudy-image-container {
  height: 450px;
  width: 600px;
  position: absolute;
  /* left: 5px; */
  top: 8px;
}

.caseStudy-image {
  object-fit: cover;
  height: 450px;
  width: 600px;
  position: absolute;
  left: 0;
  border-radius: 8px;
}

.caseStudy-content .caseStudy-description {
  min-width: 600px !important;
  /* min-height: 150px !important; */
}

.caseStudy-content {
  position: absolute;
  /* width: 750px; */
  width: 600px;
  height: 150px;
  bottom: 22px;
}

.caseStudy-close {
  color: white;
  /* float: right; */
  background-color: black;
  border: solid 1px #c3073f;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 2px;
  font-size: 25px;
  position: absolute;
  bottom: -13px;
  right: -2px;
  height: 35px;
  width: 65px;
  font-size: 14px;
  text-align: center;
  font-weight: 700;
  line-height: 0;
  padding: 0;
}

.caseStudy-close:hover {
  color: white;
  float: right;
  background-color: #c3073f;
  border: solid 1px black;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 2px;
}

.caseStudy-name {
  font-size: 30px;
  padding-left: 1px;
}

.caseStudy-description {
  color: black;
  font-size: 16px;
  text-align: left;
  overflow: scroll;
  font-family: Sansita;
  font-style: normal;
  height: 52px;
}

/* iPad Landscape */
@media screen and (max-width: 1180px) and (min-width: 401px) {
  .caseStudy {
    width: 770px;
    height: 625px;
    position: fixed;
    top: 16%;
    left: 210px;
  }

  .caseStudy-image-container {
    height: 470px;
    width: 750px;
    position: absolute;
    left: 20px;
    top: 8px;
  }

  .caseStudy-image {
    height: 450px;
    width: 730px;
  }

  .caseStudy-content {
    width: 730px !important;
    bottom: 20px;
    left: 20px;
  }

  .caseStudy-close {
    bottom: -10px;
  }

  .caseStudy-name {
    font-size: 30px;
  }
}

/* Mobile view */
@media screen and (max-width: 400px) {
  .caseStudy {
    width: 350px;
    height: 500px;
    position: fixed;
    top: 20%;
    left: 13px;
    flex-direction: column;
  }

  .caseStudy-image-container {
    height: 300px;
    width: 345px;
    position: relative;
    left: 2.5px !important;
    top: 2%;
  }

  .caseStudy-image {
    object-fit: cover;
    height: 275px;
    width: 340px;
    position: absolute;
    left: 0;
    border-radius: 8px;
  }

  .caseStudy-content {
    position: relative;
    width: 350px;
    height: 200px;
    right: 0;
    top: -3%;
  }

  .caseStudy-close {
    right: 3px;
    bottom: -7px;
  }

  .caseStudy-content .caseStudy-description {
    min-width: 320px !important;
    min-height: 112px !important;
  }

  .caseStudy-description {
    padding: 0 5px;
    overflow: scroll;
    min-height: 112px !important;
    max-height: 112px !important;
    overflow: scroll;
  }

  .caseStudy-name {
    padding-left: 6px;
  }

  .caseStudy-image {
    object-fit: cover;
    height: 275px;
    width: 340px;
    position: absolute;
    left: 0;
    border-radius: 8px;
  }

  .caseStudy-content {
    position: relative;
    width: 350px;
    height: 200px;
    right: 0;
    top: -3%;
  }

  .caseStudy-close {
    right: 3px;
    bottom: -7px;
  }

  .caseStudy-content .caseStudy-description {
    min-width: 320px !important;
    min-height: 180px !important;
  }

  .caseStudy-description {
    padding: 0 5px;
  }

  .caseStudy-name {
    padding-left: 6px;
  }
}

@media screen and (max-width: 600px) {
  .caseStudy {
    width: 350px;
    height: 500px;
    position: fixed;
    top: 12%;
    left: 13px;
    flex-direction: column;
  }

  .caseStudy-image-container {
    height: 300px;
    width: 345px;
    position: relative;
    left: 2.5px !important;
    top: 2%;
  }

  .caseStudy-image {
    object-fit: cover;
    height: 275px;
    width: 340px;
    position: absolute;
    left: 0;
    border-radius: 8px;
  }

  .caseStudy-content {
    position: relative;
    width: 350px;
    height: 200px;
    right: 0;
    top: -3%;
  }

  .caseStudy-close {
    right: 3px;
    bottom: -7px;
  }

  .caseStudy-content .caseStudy-description {
    min-width: 320px !important;
    min-height: 180px !important;
  }

  .caseStudy-description {
    padding: 0 5px;
  }

  .caseStudy-name {
    padding-left: 6px;
  }
}
