.logo:hover {
	cursor: pointer;
}

.logo img {
    width: 40px;
    height: 40px;
}

.logoSlider {
	width: 20%;
}

.logoSlider {
    display: inline-block;
    height: inherit;
    left: 1.25em;
    line-height: inherit;
    margin: 0;
    padding: 2px;
    position: absolute;
    top: 2px;
}

.logo:focus {
  outline: none;
}

.knob:focus {
  outline: none;
}



.sliderEnd {
	background: #aaa;
	float: right;
	height: 25px;
	width:1px;
	position: relative;
	top: 10px;
}

.logoSlider .track-1,
.logoSlider .sliderEnd {
	display:none;
}

.logoSlider:hover .track-1,
.logoSlider:hover .sliderEnd {
	display:block;
}

.horizontal-slider .track {
    top: 20px;
    height: 5px;
    float:left;
}

.track.track-1 {
    border-right: 1px solid #000;
    background: rgb(221, 221, 221);
}

.track {
    background: rgb(0, 0, 0);
    position: relative;
}