.slideOutDH-container {
  margin-top: 5em;
  height: 100%;
  background: black;
  width: 20vw;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 99999;
  right: 1px;
  border: solid 2px white;
  opacity: 85%;
}

.slideout-links {
  border-bottom: solid 1px white;
  width: 80%;
  margin-left: 40px;
  margin-bottom: 40px;
  color: white;
  font-size: 22px;
}
.slideout-links:hover {
  color: #f86767 !important;
  border-bottom: solid 1px #f86767 !important;
}

.slideout-link1 {
  margin-top: 70px;
}

.close-slideout {
  color: white !important;
  position: absolute;
  right: 15px;
  font-size: 24px;
  top: 15px;
  cursor: pointer;
}

@media screen and (max-width: 1450px) and (min-width: 1101px) {
  .slideOut-container {
    margin-top: 2.5em;
    min-height: 100%;
    background: black;
    width: 24vw;
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 99999;
    right: 1px;
    border: solid 2px white;
    opacity: 85%;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 1100px) and (min-width: 451px) {
  .slideOut-container {
    width: 30vw;
    margin-top: 2.9em;
  }
  .slideout-links {
    margin-left: 30px;
  }
}

@media screen and (max-width: 450px) {
  .slideOutDH-container {
    width: 100vw;
    margin-top: 55px;
    top: 0;
    height: 100vh;
    z-index: 2147483647;
  }
}

@media screen and (max-width: 600px) {
  .slideOutDH-container {
    width: 100vw;
    margin-top: 55px;
    top: 0;
    height: 100vh;
    z-index: 2147483647;
  }
}
