.container {
    width: 100%;
}

.container .card {
    position: relative;
    width: 300px;
    height: 385px;
    background: #fff;
    transition: 0.5s;
    box-shadow: 5px 5px 15px 5px #000000;
    border-radius: 8px;
}

.question {
    background-color: #F86767 !important;
}

.container .card:hover {
    box-shadow: 8px 8px 0px 0px #F86767, 36px 22px 10px 7px rgba(0,0,0,0);
}

.content {
    width: 380px;
    height: 285px;
    grid-template-rows: repeat(3, 1fr);
}

.description {
    height: 385px;
    width: 270px;
    margin-left: 13px;
    margin-top: 40px;
    margin-bottom: 25px;
    padding: 0;
    text-align: left;
    max-height: 120px;
    overflow: scroll;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: 15px;
    font-family: Sansita;
    font-style: normal;
}

.container .card .face {
    position: absolute;
    top: 0;
    left: 0; 
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
}

.container .card .face.face1 {
    box-sizing: border-box;
    text-align: center;
    background: #fff;
    color: #c3073f;
}

.container .card .face.face1 a {
    display: inline-block;
    padding: 5px 10px;
    text-decoration: none;
    color: #f86767;
    transition: 0.5s;
}

.container .card .face.face1 a:hover {
    color:#fff;
    background: #f86767;
}

.container .card .face.face2 {
    background: #FFFFFF;
    transition: 0.5s;
}

.container .card .face.face2 img{
    color: black;
}

.container .card:hover .face.face2 {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    top: 2px;
    left: 50%;
    transform: translateX(-50%);
    background: transparent;
}

.container .card:hover .face.face2 img {
    margin-top: 0;
    width: 150px;
    background: transparent;
}

.container .card:hover .face.face2 .question img {
    background-color: none;
}

.question:hover img {
    background-color: white !important;
    position: absolute;
    top: 0.5px;
}

.tags {
    overflow: auto;
    white-space: nowrap;
    max-width: 300px;
    display: flex;
    justify-content: flex-start;
    gap: 5px;
    margin-bottom: 5px;
    margin-left: 13px;
}

.tag {
    padding: 0 5px;
    border-radius: 15px;
    border: solid 1.5px black;
    cursor: pointer;
    color: #F86767;
    font-size: 12px;
    font-family: Sansita;
    font-style: normal;
}

.tag:hover {
    text-decoration: underline;
}

.cardImage {
    width: 255px;
}

.arrow {
    justify-content: right;
    color: black;
    font-size: 1.5em;
    cursor: pointer;
    margin-top: 10px;
}

.arrow:hover {
    color:#fff;
    background: #f86767;
    transition: 0.5s;
}
