.overflow-shown {
  overflow: auto;
}

.page-title {
  display: block;
  color: white;
  font-size: 28px;
}
.project-start {
  background: #f86767;
  width: 100%;
  height: 92.5vh;
  overflow: scroll !important;
  padding-top: 125px;
}

.contact-form {
  position: relative;
  top: 4px;
  margin: auto;
  width: 80%;
  margin: 0 auto;
}

form {
  background-color: white;
  padding: 45px;
  border-radius: 10px;
  box-shadow: 5px 5px 10px 2px rgba(71, 68, 68, 0.8);
  padding-bottom: 60px;
}

.input-field {
  border: 1px solid black !important;
  color: grey !important;
  border-radius: 5px;
}

.button-big {
  background-color: black !important;
  color: rgb(255, 255, 255) !important;
  padding-left: 65px;
  padding-right: 65px;
  float: right;
}

textarea {
  height: 150px;
}

::placeholder {
  color: grey !important;
}

input[type="text"],
textarea {
  background-color: white;
}

/* Bootstrap dropdown CSS */

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media screen and (max-width: 450px) {
  .initiate-project {
    width: 114% !important;
    margin: -5px -22px;
  }
  .initiate-project-form {
    padding: 30px;
    padding-bottom: 52px;
  }
  .project-start {
    padding-top: 40px;
  }
  .page-title {
    margin-left: 24px;
  }
}

@media screen and (max-width: 600px) {
  .initiate-project {
    width: 114% !important;
    margin: -5px -22px;
  }
  .initiate-project-form {
    padding: 30px;
    padding-bottom: 52px;
  }
  .project-start {
    padding-top: 40px;
  }
  .page-title {
    margin-left: 24px;
  }
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

/* Typeahead css */

.rbt .rbt-input-main::-ms-clear {
  display: none;
}

/**
 * Menu
 */
.rbt-menu {
  margin-bottom: 2px;
}
.rbt-menu > .dropdown-item {
  overflow: hidden;
  text-overflow: ellipsis;
}
.rbt-menu > .dropdown-item:focus {
  outline: none;
}
.rbt-menu-pagination-option {
  text-align: center;
}

/**
 * Multi-select Input
 */
.rbt-input {
  background: rgba(255, 255, 255, 0.075);
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.25);
  border-radius: 4px;
  outline: 0;
  padding: 0 1em;
}

.rbt-input-multi {
  cursor: text;
  overflow: hidden;
  position: relative;
}
.rbt-input-multi.focus {
  box-shadow: 0 0 0 1px #fafafa;
  border: solid 1px;
  outline: 0;
}
.rbt-input-multi.form-control {
  height: auto;
}
.rbt-input-multi.form-control[disabled] {
  background-color: #e9ecef;
  opacity: 1;
}
.rbt-input-multi.is-invalid.focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.rbt-input-multi.is-valid.focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.rbt-input-multi input::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}
.rbt-input-multi input:-ms-input-placeholder {
  color: #6c757d;
}
.rbt-input-multi input::-webkit-input-placeholder {
  color: #6c757d;
}

.rbt-input-multi .rbt-input-wrapper {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}
.rbt-input-multi .rbt-input-main {
  margin: 1px 0 4px;
}

/**
 * Close Button
 */
.rbt-close {
  z-index: 1;
}
.rbt-close-lg {
  font-size: 24px;
}

/**
 * Token
 */
.rbt-token {
  background-color: #e7f4ff;
  border: 0;
  border-radius: 0.25rem;
  color: #007bff;
  display: inline-block;
  margin-right: 10px;
  padding: 7px;
  position: relative;
}
.rbt-token-disabled {
  background-color: rgba(0, 0, 0, 0.1);
  color: #495057;
  pointer-events: none;
}
.rbt-token-removeable {
  cursor: pointer;
  padding-right: 30px;
}
.rbt-token-active {
  background-color: #007bff;
  color: #fff;
  outline: none;
  text-decoration: none;
}
.rbt-token .rbt-token-remove-button {
  bottom: 0;
  color: inherit;
  font-weight: normal;
  opacity: 1;
  outline: none;
  padding: 0 7px;
  position: absolute;
  right: 0;
  text-shadow: none;
  margin-right: 0px !important;
}

/**
 * Loader + CloseButton container
 */
.rbt-aux {
  align-items: center;
  display: flex;
  bottom: 0;
  justify-content: center;
  pointer-events: none;
  /* Don't block clicks on the input */
  position: absolute;
  right: 0;
  top: 0;
  width: 34px;
}
.rbt-aux-lg {
  width: 46px;
}
.rbt-aux .rbt-close {
  margin-top: -4px;
  pointer-events: auto;
  /* Override pointer-events: none; above */
}

.has-aux .rbt-input {
  padding-right: 34px;
}

.rbt-highlight-text {
  background-color: inherit;
  color: inherit;
  font-weight: bold;
  padding: 0;
}

/**
 * Input Groups
 */
.input-group > .rbt {
  flex: 1;
}
.input-group > .rbt .rbt-input-hint,
.input-group > .rbt .rbt-aux {
  z-index: 5;
}
.input-group > .rbt:not(:first-child) .form-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .rbt:not(:last-child) .form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

@media only screen and (max-width: 1180px) {
  .page-title {
    font-size: 24px;
  }
}

@media only screen and (max-width: 667px) {
  .page-title {
    font-size: 21px;
  }
}
