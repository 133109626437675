.faq-container {
  padding-left: 20px;
  padding-right: 20px;
}

.faq-heading {
  margin-bottom: 10px !important;
}

.faq-question-container {
  padding-top: 20px;
}

.expand-all-btn {
  border: solid white 1px;
  height: 50px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-radius: 5px;
  margin-bottom: 20px;
  background-color: #f86767;
  color: white;
  box-shadow: 5px 5px 10px 5px #a8a8a8;
  cursor: pointer;
}

.expand-all-btn-icon {
  font-size: 30px;
}

.expand-all-btn:hover {
  border: solid #f86767 1px;
  background-color: #eeeeee;
  color: #f86767;
  box-shadow: 5px 5px 10px 5px #e65e5e;
}

@media screen and (max-width: 450px) {
  .expand-all-btn {
    height: 45px;
    width: 135px;
    font-size: 14px;
  }
  .expand-all-btn-icon {
    font-size: 20px;
  }
}

@media screen and (max-width: 600px) {
  .expand-all-btn {
    height: 45px;
    width: 135px;
    font-size: 14px;
  }
  .expand-all-btn-icon {
    font-size: 20px;
  }
}
