.faq-container {
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  min-height: 100vh;
  background: #fdf6ec;
}

.faq-heading {
  margin-bottom: 10px !important;
}

.faq-question-container {
  padding-top: 20px;
}

.faq-q-a {
  font-size: 32px;
  margin-bottom: 10px !important;
  margin-left: 18px;
  margin-top: 4px;
  color: #e65e5e;
}

.faq-question {
  font-size: 20px;
  margin: 0 !important;
  padding-left: 10px;
  color: #3a3845;
}

.faq-answer {
  font-size: 18px;
}

.answers {
  padding-left: 51px;
}

.faq-btn {
  cursor: pointer;
  font-size: 35px;
  height: 50px;
  width: 50px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: white;
  background: #f86767;
  border: solid 1px white;
}

.faq-btn:hover {
  border: solid #f86767 1px;
  background-color: #eeeeee;
  color: #f86767;
}

.faq-q-plus {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.faq-single-container {
  margin-bottom: 35px;
  border: solid black 1px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;
  border-radius: 5px;
  background-color: aliceblue;
  box-shadow: 5px 5px 10px 5px #a8a8a8;
}

.answer {
  padding-left: 100px !important;
  color: black !important;
}

.question-unset {
  color: black;
  font-size: 20px;
  margin: 0 !important;
  padding-left: 10px;
}

@media screen and (max-width: 450px) {
  .answer {
    padding-left: 82px !important;
    color: black !important;
  }
  .faq-question {
    font-size: 16px;
    margin: 0 !important;
    padding-left: 10px;
    color: #3a3845;
  }
  .faq-btn {
    color: #f86767;
    cursor: pointer;
    font-size: 30px;
    height: 35px;
    width: 60px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: solid 1px black;
    background-color: silver;
  }
  .question-unset {
    font-size: 16px;
  }
  .faq-q-a {
    font-size: 32px;
    margin-bottom: 10px !important;
    margin-left: 12px;
    margin-top: 4px;
    color: #e65e5e;
  }
}

@media screen and (max-width: 600px) {
  .answer {
    padding-left: 82px !important;
    color: black !important;
  }
  .faq-question {
    font-size: 16px;
    margin: 0 !important;
    padding-left: 10px;
    color: #3a3845;
  }
  .faq-btn {
    color: #f86767;
    cursor: pointer;
    font-size: 30px;
    height: 35px;
    width: 60px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: solid 1px black;
    background-color: silver;
  }
  .question-unset {
    font-size: 16px;
  }
  .faq-q-a {
    font-size: 32px;
    margin-bottom: 10px !important;
    margin-left: 12px;
    margin-top: 4px;
    color: #e65e5e;
  }
}
