#footer {
  position: absolute;
  padding: 0;
  bottom: 70px;
  bottom: 128px;
  left: -150px;
}

@media screen and (max-width: 1100px) and (min-width: 451px) {
  #footer {
    left: -94px;
  }

  .footer-bottom-text {
    margin: 0;
  }

  .contact-details {
    top: 844px;
    left: 228px;
  }

  .footer-heading {
    left: 54px;
  }
}

@media screen and (max-width: 450px) {
  #footer {
    left: 52px;
  }

  .footer-bottom-text {
    top: 0px;
  }
}

#footer .copyright {
  margin: 0;
  padding: 0;
  text-align: left;
  margin-left: -20px;
}

#footer ul {
  margin: 0;
}

#footer ul.alt li {
  padding: 0;
  display: inline-block;
  border-right-color: grey;
}

#notices {
  width: 100%;
  background: rgba(255, 255, 255, 0.3);
  position: absolute;
  padding: 0;
  padding-left: 10px;
  bottom: 10px;
  color: #fff;
  text-align: center;
}

#notices .copyright {
  margin: 0;
  padding: 0;
  text-align: left;
  margin-left: -20px;
}

#notices ul {
  margin: 0;
}

#notices ul.alt li {
  padding: 0;
  display: inline-block;
  border-right-color: grey;
}

.terms {
  color: gray !important;
}

.contact-footer {
  color: white !important;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 20px;
  background-color: black;
  top: -30px;
  height: 96px;
}

@media screen and (max-width: 600px) {
  #footer {
    left: 52px;
  }

  .footer-bottom-text {
    top: 0px;
  }
}

#footer .copyright {
  margin: 0;
  padding: 0;
  text-align: left;
  margin-left: -20px;
}

#footer ul {
  margin: 0;
}

#footer ul.alt li {
  padding: 0;
  display: inline-block;
  border-right-color: grey;
}

#notices {
  width: 100%;
  background: rgba(255, 255, 255, 0.3);
  position: absolute;
  padding: 0;
  padding-left: 10px;
  bottom: 10px;
  color: #fff;
  text-align: center;
}

#notices .copyright {
  margin: 0;
  padding: 0;
  text-align: left;
  margin-left: -20px;
}

#notices ul {
  margin: 0;
}

#notices ul.alt li {
  padding: 0;
  display: inline-block;
  border-right-color: grey;
}

.terms {
  color: gray !important;
}

.contact-footer {
  color: white !important;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 20px;
  background-color: black;
  top: -30px;
  height: 96px;
}

@media only screen and (max-width: 667px) {
  .contact-footer {
    font-size: 12px;
  }
}

@media screen and (max-width: 1441px) and (min-width: 1101px) {
  #footer {
    position: absolute;
    padding: 0;
    bottom: 70px;
    margin-left: unset;
    left: -166px;
  }
}
