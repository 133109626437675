.categories-list {
  list-style: none;
  margin-bottom: 20px;
  padding: 0px;
  display: flex;
  box-shadow: 0px 2px 20px 0px rgb(235 219 5 / 10%);
  overflow: hidden;
  border: solid 1px black;
  border-radius: 5px;
  height: 26px;
}

.category-item {
  padding-left: unset;
}

.category-item__btn {
  background-color: transparent;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  min-width: 150px;
  font-family: Sansita;
  font-style: normal;
  font-size: 18px;
  font-weight: 900;
  color: #e6e6e6;
  height: 34px;
  line-height: 0px;
  transition: border 0.2s, background 0.2s, color 0.2s;
}

.category-item__btn:focus {
  background: #f86767;
  color: black;
  border-color: #f86767;
}

.category-item__btn:active {
  background: #f86767;
  color: black;
  border-color: #f86767;
}

.category-item__btn:hover {
  background: #f86767;
  color: black;
  border-right-color: #f86767;
}

.category-item__link:focus {
  background: #f86767;
  color: black;
  border-color: #f86767;
}

.category-item__link:active {
  background: #f86767;
  color: black;
  border-color: #f86767;
}

.category-item__link:hover {
  background: #f86767;
  color: black;
  border-right-color: #f86767;
}

@media screen and (max-width: 1100px) and (min-width: 451px) {
  .categories-list {
    max-width: 98%;
    justify-content: center;
    position: unset;
    left: unset;
    top: unset;
  }
}

@media screen and (max-width: 450px) {
  .categories-list {
    max-width: 98%;
    position: relative;
    left: 15px;
    top: unset;
  }
  .category-item__btn {
    min-width: 60px;
    padding: 11px;
  }

  .category-item__btn:last-of-type {
    padding-right: 30px;
  }
}

@media screen and (max-width: 600px) {
  .categories-list {
    max-width: 98%;
    position: relative;
    left: 15px;
    top: unset;
  }
  .category-item__btn {
    min-width: 60px;
    padding: 11px;
  }

  .category-item__btn:last-of-type {
    padding-right: 30px;
  }
}
