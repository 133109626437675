.split-header {
  list-style: none;
}

.header-site-links {
  gap: 25px;
  display: flex;
  flex-direction: row;
  width: 230px;
  margin-right: 30px;
  color: white !important;
}

.heading {
  position: unset !important;
  height: 3.25em;
  width: 100%;
  display: flex;
  font-size: 18px;
  border-bottom: 1px solid #000;
}

#header ul {
  padding: 0;
  margin-bottom: 0;
}

.online {
  background: #82fa58;
  /*#0EBF0E*/
}

.online:hover {
  background: #11da03 !important;
  color: #000 !important;
}

.container-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 3em;
  width: 100%;
  font-size: 20px;
  margin-left: 20px;
}

.container-right {
  display: flex !important;
  justify-content: flex-end;
  align-items: center;
  height: 3.25em;
  width: 100%;
  grid-gap: 20px;
  gap: 15px;
  /* color: white; */
  margin-right: 20px;
  color: black !important;
}

.emailLink {
  margin-right: 10px;
  padding-top: 5px;
  display: flex;
  font-size: 18px;
  cursor: pointer;
}

.emailLink:hover {
  background-color: #000;
  color: white;
  padding: 2px 5px 2px 5px;
}

.numberLink {
  display: flex;
  width: 210px;
  color: #000 !important;
}

.numberLink:hover {
  background-color: #000;
  color: white !important;
  padding: 2px 5px 2px 5px;
}

.header-social {
  color: #000;
  padding: 2px 5px 2px 5px;
}

.header-social:hover {
  background-color: #000;
  color: white;
  padding: 2px 5px 2px 5px;
}

.envelope-icon-header {
  padding-top: 5px;
  padding-right: 5px;
}

.phone-icon-header {
  /* position: absolute;
  top: 1.2em;
  left: 710px; */
  padding-top: 5px;
  padding-right: 5px;
}

.menu-hover {
  color: #000;
  cursor: pointer;
}

.menu-hover:hover {
  background: unset !important;
  color: lightskyblue;
}

/* iPad view */
@media screen and (max-width: 1100px) and (min-width: 451px) {
  .heading {
    width: 100%;
    font-size: 15px;
  }
  .container-right {
    margin-left: 50px;
    margin-top: 2px;
    height: 3.5em;
  }
  .emailLink {
    font-size: 15px;
  }
  .numberLink {
    width: 240px;
  }
}

@media screen and (max-width: 450px) {
  .emailLink {
    display: none;
  }
  .header-social {
    display: none;
  }
  .client-area {
    display: none;
  }
  .numberLink {
    margin-left: 10px;
    width: 390px;
    font-size: 16px;
  }
  .heading {
    width: 100%;
  }
  .header-site-links {
    width: 275px;
    height: 3.5em;
    /* border-bottom: 1px solid red; */
    margin-right: unset;
    align-items: center;
  }
}

@media screen and (max-width: 600px) {
  .emailLink {
    display: none;
  }
  .header-social {
    display: none;
  }
  .client-area {
    display: none;
  }
  .numberLink {
    margin-left: 10px;
    width: 390px;
    font-size: 16px;
  }
  .heading {
    width: 100%;
  }
  .header-site-links {
    width: 275px;
    height: 3.5em;
    /* border-bottom: 1px solid red; */
    margin-right: unset;
    align-items: center;
  }
}
