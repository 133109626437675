.card-holder {
  height: 400px;
  width: 330px;
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  box-shadow: 2px 2px 2px 2px #000000;
}

.card-holder:hover {
  background-color: #f86767;
  box-shadow: 8px 8px 0px 0px #fff;
}

.service-desc {
  display: none;
  position: relative;
}

.service-title:hover {
  color: white;
}

.service-title:hover + .service-desc {
  display: block;
  color: white;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
}

.service-title {
  position: relative;
  transition: transform ease 0.5s;
  padding-left: 10px;
  padding-right: 10px;
}
.service-title:hover {
  transform: translate(0, -20px);
}

.service-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
