.description-containerDH {
  position: fixed;
  top: 50px;
  left: 90px;

  z-index: 999;
}

.description-containerDH .subtitleDH {
  display: none;
}

.description-containerDH:hover .titleDH {
  display: none;
}

.description-containerDH:hover .subtitleDH {
  display: flex;
  position: fixed;
  width: 500px;
  top: 60px;
  font-size: 20px;
}

@media screen and (max-width: 1440px) and (min-width: 1101px) {
  .description-containerDH {
    top: 45px;
  }
}

/* iPad view */
@media screen and (max-width: 1100px) and (min-width: 451px) {
  .description-containerDH {
    display: none !important;
  }
}

@media screen and (max-width: 450px) {
  .description-containerDH {
    display: none !important;
  }
}

@media screen and (max-width: 600px) {
  .description-containerDH {
    display: none !important;
  }
}
