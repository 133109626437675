.login-bg {
  background: black !important;
}

.login-message {
  display: flex;
  justify-content: center;
  padding-top: 40px;
}

#form_container {
  padding-top: 100px;
  max-width: 60%;
  padding-bottom: 23%;
}

.contact-button {
  float: right;
  background-color: black !important;
  color: white !important;
  font-size: 13px;
  height: 40px;
  width: 150px;
}

.fields {
  padding-bottom: 2%;
}
/* 
 */

@media screen and (max-width: 450px) {
  #form_container {
    padding-top: 100px;
    max-width: 90%;
    padding-bottom: 40%;
  }

  .fields {
    padding-bottom: 10%;
    width: 110%;
  }

  .form_description p {
    padding-bottom: 25%;
  }
}

@media screen and (max-width: 600px) {
  #form_container {
    padding-top: 100px;
    max-width: 90%;
    padding-bottom: 40%;
  }

  .fields {
    padding-bottom: 10%;
    width: 110%;
  }

  .form_description p {
    padding-bottom: 25%;
  }
}

/* iPad Landscape */
@media screen and (max-width: 1100px) and (min-width: 451px) {
  #form_container {
    padding-top: 100px;
    max-width: 90%;
    padding-bottom: 80%;
  }

  .fields {
    width: 70%;
    padding-right: 10%;
  }

  .form_description p {
    padding-bottom: 20%;
  }
}
