.message-sent {
  margin: 2em;
  padding: 2em;
}

.heading-title {
  position: relative;
  left: 120px;
  font-size: 28px;
  color: white;
  top: 30px;
}

.form-input {
  background-color: white !important;
}

.contact-button {
  float: right;
  background-color: black !important;
  color: white !important;
  font-size: 13px;
  height: 40px;
  width: 150px;
  margin-top: 10px;
}

.message-form {
  margin-bottom: 100px;
  width: 80%;
  position: relative;
  left: 120px;
  top: 25px;
}

@media screen and (max-width: 450px) {
  .heading-title {
    font-size: 17px;
    left: 11px;
  }

  .message-form {
    left: 11px;
    width: 94%;
  }

  .form-inputs {
    display: block;
    width: 118%;
    position: relative;
    left: -25px;
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 600px) {
  .heading-title {
    font-size: 17px;
    left: 11px;
  }

  .message-form {
    left: 11px;
    width: 94%;
  }

  .form-inputs {
    display: block;
    width: 118%;
    position: relative;
    left: -40px;
    padding-bottom: 20px;
  }
}

/* iPad Landscape */
@media screen and (max-width: 1100px) and (min-width: 451px) {
  .heading-title {
    font-size: 24px;
  }

  .form-inputs {
    padding-bottom: 12px;
  }
}
