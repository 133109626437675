.ServiceBox {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 100px;
}

.hover-lastword {
  color: #f86767 !important;
}

.outer-page {
  background: url(../../images/rainbow-what-we-do.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(47, 79, 79, 0.7);
  background-blend-mode: multiply;
  flex-direction: column;
  display: flex;
  height: 92.7vh;
  width: 100vw;
  /* padding-top: 3.25em; */
  padding-bottom: 0;
  padding-left: 0.2em;
}
.newTitle {
  font-size: 1em !important;
  text-align: center !important;
  padding-bottom: 10px !important;
}

.newSubtitle {
  font-size: 0.6em !important;
}

.ServiceTitle {
  text-align: center;
  color: white !important;
  font-size: 44px;
  margin-top: 50px;
}
.ServiceTitle span {
  color: #f86767 !important;
}

#whatwedo .inner {
  height: 90%;
  width: 100%;
}

.bottom-btn {
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.btn-white {
  height: 79px;
  width: 276px;
  font-size: 18px;
  background-color: #fff !important;
  color: black !important;
}

.btn-pink {
  height: 79px;
  width: 276px;
  font-size: 18px;
  background-color: #f86767 !important;
  color: white !important;
}

/* Mobile view */
@media screen and (max-width: 450px) {
  body {
    overflow: auto !important;
  }
  .heading {
    height: unset !important;
  }
  .outer-page {
    /* width: fit-content; */
    height: unset !important;
  }
  .ServiceBox {
    padding-top: 120px;
    flex-direction: column;
    width: 100% !important;
    padding-left: 5px;
    padding-right: 5px;
    gap: 40px !important;
  }

  .ServiceTitle {
    text-align: center;
    color: white !important;
    font-size: 34px;
    margin-top: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
  }
  .bottom-btn {
    flex-direction: column;
    padding-top: 160px;
    align-items: unset !important;
    padding-left: 85px !important;
    height: 55vh;
  }
  .btn-pink {
    width: 280px;
  }
  .btn-white {
    width: 280px;
  }
}

@media screen and (max-width: 400px) {
  body {
    overflow: auto !important;
  }
  .heading {
    height: unset !important;
  }
  .outer-page {
    /* width: fit-content; */
    height: unset !important;
  }
  .ServiceBox {
    padding-top: 120px;
    flex-direction: column;
    width: 100% !important;
    padding-left: 5px;
    padding-right: 5px;
    gap: 40px !important;
  }

  .ServiceTitle {
    text-align: center;
    color: white !important;
    font-size: 34px;
    margin-top: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
  }
  .bottom-btn {
    flex-direction: column;
    padding-top: 160px;
    align-items: unset !important;
    padding-left: 45px;
    height: 55vh;
  }
  .btn-pink {
    width: 280px;
  }
  .btn-white {
    width: 280px;
  }
}

@media screen and (max-width: 600px) {
  body {
    overflow: auto !important;
  }
  .heading {
    height: unset !important;
  }
  .outer-page {
    /* width: fit-content; */
    height: unset !important;
  }
  .ServiceBox {
    padding-top: 120px;
    flex-direction: column;
    width: 100% !important;
    padding-left: 5px;
    padding-right: 5px;
    gap: 40px !important;
  }

  .ServiceTitle {
    text-align: center;
    color: white !important;
    font-size: 34px;
    margin-top: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
  }
  .bottom-btn {
    flex-direction: column;
    padding-top: 10px;
    align-items: unset !important;
    padding-left: 157px;
    padding-top: 10px;

    height: 55vh;
  }
  .btn-pink {
    width: 280px;
  }
  .btn-white {
    width: 280px;
  }
}

/* iPad Landscape */
@media screen and (max-width: 1100px) and (min-width: 451px) {
  .ServiceBox {
    gap: 30px;
    width: 95%;
    height: unset !important;
  }
  .ServiceTitle {
    margin-top: 30px;
  }
  .bottom-btn {
    height: 25vh;
  }
}
