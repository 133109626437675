.secondHeader {
  border-bottom: 1px solid #000;
  /* position: unset !important; */
  height: 3.5em;
  width: 100%;
  background-color: #fff;
  display: flex;

  position: sticky !important;
  top: 2.87rem;
  z-index: 999;
}

.online {
  background: #82fa58;
  /*#0EBF0E*/
}

.online:hover {
  background: #11da03 !important;
  color: #000 !important;
}

.secondHeader > .logo {
  top: unset !important;
}

.tags {
  display: inline-block;
}

.tags:hover {
  color: #f86767;
}

.tags {
  color: black;
}

.secondHeader-tags:hover {
  padding-bottom: 22px;
  border-bottom: 2px solid #f86767;
  cursor: pointer;
}

/* .secondHeader-tags:active {
  padding-bottom: 3px;
  border-bottom: 2px solid #f86767;
  cursor: pointer;
} */

.secondHeader-tags:focus {
  padding-bottom: 22px;
  border-bottom: 2px solid #f86767;
  cursor: pointer;
  color: #f86767;
}

.secondHeader-user {
  color: black !important;
  margin-top: 4px;
}

.secondHeader-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 3.25em;
  width: 100%;
  font-size: 20px;
  margin-left: 20px;
}

.secondHeader-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 4em;
  width: 100%;
  grid-gap: 20px;
  gap: 30px;
  color: white;
  margin-right: 20px;
  color: black !important;
}
.right-slideout-menu-mobile {
  display: none;
}

@media screen and (max-width: 450px) {
  .secondHeader-right {
    display: none;
  }
  .right-slideout-menu-mobile {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 3.5em;
    width: 100%;
    grid-gap: 20px;
    gap: 30px;
    color: white;
    margin-right: 20px;
    color: black !important;
  }
}

@media screen and (max-width: 600px) {
  .secondHeader-right {
    display: none;
  }
  .right-slideout-menu-mobile {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 3.5em;
    width: 100%;
    grid-gap: 20px;
    gap: 30px;
    color: white;
    margin-right: 20px;
    color: black !important;
  }
}

@media screen and (max-width: 1440px) and (min-width: 1101px) {
  .secondHeader-tags:hover {
    padding-bottom: 17px;
  }
  .secondHeader-tags:focus {
    padding-bottom: 17px;
  }
}
