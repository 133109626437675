.heading {
  height: unset !important;
}

/* iPad view */
@media screen and (max-width: 1100px) and (min-width: 451px) {
  .description-container {
    display: none !important;
  }
}

@media screen and (max-width: 450px) {
  .description-container {
    display: none !important;
  }
}

@media screen and (max-width: 600px) {
  .description-container {
    display: none !important;
  }
}
