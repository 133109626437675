.card-wrapper-implement {
  height: 45vh;
  width: 45vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 80px 80px;
  margin-left: 27%;
}

.services-offered {
  background-color: rgb(255, 255, 255);
  min-height: 100vh;
}

.card-wrapper-implement .tile {
  margin-top: 100px;
}

.header .main-title {
  color: black;
}

.last-word {
  color: #f86767;
}

.down-arrow-implement {
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 20px;
}

.portfolioLink_btn {
  height: 79px;
  width: 276px;
  font-size: 18px;
  background-color: #f86767 !important;
  color: white !important;
  cursor: pointer;
}

@media screen and (max-width: 450px) {
  .card-wrapper-implement {
    margin-top: 140%;
    flex-direction: column;
    height: 55vh;
    width: 55vw;
    margin-left: 22%;
  }

  .down-arrow-implement {
    margin-top: 140%;
  }
}

@media screen and (max-width: 600px) {
  .card-wrapper-implement {
    margin-top: 140%;
    flex-direction: column;
    height: 55vh;
    width: 55vw;
    margin-left: 22%;
  }

  .down-arrow-implement {
    margin-top: 140%;
  }
}

@media only screen and (max-height: 800px) and (min-height: 700px) {
  .down-arrow {
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 55px;
  }
  .card-wrapper {
    padding-top: 10px;
  }
}

@media only screen and (max-height: 800px) and (min-height: 700px) {
  .down-arrow-implement {
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 55px;
  }
  .card-wrapper {
    padding-top: 10px;
  }
}
