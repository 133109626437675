.topHeader {
  height: 3.25em;
  width: 100%;
  background-color: #2f2f32;
  display: flex;

  position: sticky;
  top: 0;
  z-index: 1000;
}

.EmailLink-container {
  display: flex;
  cursor: pointer;
}
.phone-container {
  display: flex;
  padding-top: 11px;
  padding-bottom: 9px;
  padding-left: 8px;
}

.EmailLink {
  margin-right: 10px;
  padding-top: 5px;
  font-size: 20px;
}

.topHeader-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 3.5em;
  width: 100%;
  grid-gap: 20px;
  gap: 20px;
  color: white;
  margin-right: 20px;
}

.topHeader-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 2.8em;
  width: 100%;
  font-size: 22px;
  gap: 15px;
  margin-left: 20px;
}

.topHeader-links {
  color: white;
}

.topHeader-links:visited {
  color: white;
  opacity: 100%;
}

.fb-social:hover {
  color: #4a76cc;
}

.insta-social:hover {
  color: #af37ff;
}

.twt-social:hover {
  color: #00acee;
}

.linkedin-social:hover {
  color: #1593ce;
}

.whtsapp-social:hover {
  color: #25d366;
  cursor: pointer;
}

.topHeader-list {
  position: absolute;
  top: 3.23em;
  color: red;
  right: 210px;
  list-style: none;
  height: 150px;
  width: 200px;
  background-color: #2f2f32;
  text-align: center;
  z-index: 999;
  padding-right: 20px;
  padding-top: 20px;
  border-right: solid 2px #d1d1d1;
  border-bottom: solid 2px #d1d1d1;
  border-left: solid 2px #d1d1d1;
  padding-left: 8px;
}

.topHeader-list-forPhoneDropDown {
  position: absolute;
  top: 3.23em;
  color: red;
  right: 8px;
  list-style: none;
  height: 150px;
  width: 196px;
  background-color: #2f2f32;
  text-align: center;
  z-index: 999;
  padding-right: 20px;
  padding-top: 20px;
  padding-left: 7px;
  border-right: solid 2px #d1d1d1;
  border-bottom: solid 2px #d1d1d1;
  border-left: solid 2px #d1d1d1;
}

.topHeader-list-item-one {
  padding-bottom: 20px;
  display: flex;
  padding-left: 0 !important;
  cursor: pointer;
}

.topHeader-list-item-two {
  padding-bottom: 20px;
  display: flex;
  padding-left: 0 !important;
  cursor: pointer;
}

.topHeader-btn-one {
  width: 180px;
  font-size: 12px;
  text-align: left;
  padding-left: 18px;
  padding-top: 1px;
  padding-right: 10px;
}

.topHeader-btn-two {
  width: 180px;
  font-size: 12px;
  text-align: left;
  padding-left: 18px;
  padding-top: 1px;
  padding-right: 10px;
}

.topHeader-btn-one:hover {
  background-color: #f86767;
  color: white;
}

.topHeader-btn-two:hover {
  background-color: #f86767;
  color: white;
}

.topHeader-icon-one {
  margin-right: 2px;
}

.topHeader-icon-one-forPhoneDropDown {
  position: relative;
  top: 5px;
  margin-right: 5px;
}

.skype-text {
  position: relative;
  top: -38px;
  left: 30px;
}

.webtalk-text {
  position: relative;
  left: 20px;
}

.topHeader-icon-two {
  margin-right: 2px;
}

.EmailLink-container:hover {
  background-color: #f86767;
  height: 45px;
  width: 194px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.phone-container:hover {
  background-color: #f86767;
  cursor: pointer;
}

@media screen and (min-width: 1441px) {
  .topHeader-list {
    width: 228px;
    top: 3.25em;
    right: 236px;
    height: 170px;
    padding-top: 25px;
  }

  .topHeader-list-forPhoneDropDown {
    width: 228px;
    top: 3.25em;
    right: 0px;
    height: 170px;
    padding-top: 25px;
  }

  .topHeader-list-item-one {
    padding-bottom: 30px;
  }

  .topHeader-btn-one {
    width: 195px;
    font-size: 14px;
    text-align: left;
    padding-left: 14px;
    padding-top: 1px;
  }

  .topHeader-btn-two {
    width: 195px;
    font-size: 14px;
    text-align: left;
    padding-left: 14px;
    padding-top: 1px;
  }

  .EmailLink-container:hover {
    background-color: #f86767;
    height: 52px;
    width: 224px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 450px) {
  .phone-tag-writing {
    display: none;
  }
  .EmailLink {
    margin-right: -5px;
    font-size: 20px;
    margin-bottom: 10px;
  }
  .topHeader-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 3.5em;
    width: 100%;
    color: white;
    margin-right: 24px;
  }
  .topHeader-left {
    grid-gap: 12px;
    gap: 12px;
    margin-left: 20px;
  }

  .topHeader-list {
    top: 108px;
    left: 0px;
    width: 375px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
  }

  .topHeader-list-forPhoneDropDown {
    top: 108px;
    left: 0px;
    width: 375px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
  }

  .topHeader-icon-one-forPhoneDropDown {
    left: -60px;
    top: 7px;
  }

  .skype-text {
    top: -42px;
    left: 15px;
  }

  .webtalk-text {
    left: 5px;
  }

  .topHeader-btn-one {
    width: 350px;
    font-size: 14px;
    text-align: center;
  }

  .topHeader-btn-two {
    width: 350px;
    font-size: 14px;
    text-align: center;
  }

  .EmailLink-container:hover {
    color: #f86767;
    background-color: unset;
    height: unset;
    width: unset;
    align-items: flex-end;
    height: 32px;
  }

  .phone-container:hover {
    color: #f86767;
    background-color: unset;
  }
}

@media screen and (max-width: 600px) {
  .phone-tag-writing {
    display: none;
  }
  .EmailLink {
    margin-right: -5px;
    font-size: 20px;
    margin-bottom: 10px;
  }
  .socialHeader-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 3.5em;
    width: 100%;
    color: white;
    margin-right: 24px;
  }
  .socialHeader-left {
    grid-gap: 12px;
    gap: 12px;
    margin-left: 20px;
  }

  .socialHeader-list {
    top: 108px;
    left: 0px;
    width: 375px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .socialHeader-list-forPhoneDropDown {
    top: 108px;
    left: 0px;
    width: 375px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .socialHeader-icon-one-forPhoneDropDown {
    left: -60px;
    top: 7px;
  }

  .skype-text {
    top: -42px;
    left: 15px;
  }

  .webtalk-text {
    left: 5px;
  }

  .socialHeader-btn-one {
    width: 350px;
    font-size: 14px;
    text-align: center;
  }

  .socialHeader-btn-two {
    width: 350px;
    font-size: 14px;
    text-align: center;
  }

  .EmailLink-container:hover {
    color: #f86767;
    background-color: unset;
    height: unset;
    width: unset;
    align-items: flex-end;
    height: 32px;
  }

  .phone-container:hover {
    color: #f86767;
    background-color: unset;
  }
}
